<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-row>
            <v-col
                    cols="2"
                    v-for="(item, index) in modules"
                    v-bind:key="index"
                    class="viewCard"
            >
                <router-link :to="item.link">
                    <HoverCard :cardData="item"></HoverCard>
                </router-link>
            </v-col>
        </v-row>
    </v-content>
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'
    export default {
        name: "Dashboard",
        watch: {},
        props: [
            'moduleName'
        ],
        components: {
            HoverCard
        },
        computed: {
            modules() {
                return [
                    {
                        name: this.$t('trekking_report'),
                        type: "monthlyYearlyReport",
                        link: "/reportModules/reportTrekking/monthly-yearly",
                        icon: "mdi-chart-areaspline"
                    },
                    {
                        name: this.$t('trekking_restricted_areas_report'),
                        type: "restrictedAreasyReport",
                        link: "/reportModules/reportTrekking/restrictedAreas",
                        icon: "mdi-chart-areaspline"
                    },
                    {
                        name: this.$t('trekking_restricted_areas_processed_report'),
                        type: "restrictedAreasAllReport",
                        link: "/reportModules/reportTrekking/restrictedAreasReport",
                        icon: "mdi-chart-areaspline"
                    },
                    ]
            },
            items() {
                return[
                    {
                        text: this.$t('reports'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('modules_name.trekking'),
                        disabled: true,
                        exact: true
                    }
                    ]
            }
            },
        mounted() {
        },
        methods: {}
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>